export const LEADDELIVERY_API_URL = '/leaddelivery/v2/digitalreferrals/';
export const LEAD_TARGETS_API_URL_LEAD_BUSINESS = '/lead-business/v1/lead-targets';

const LEAD_TYPES = {
  BUY: 'Buy',
};

// Strategies, how to get data
export const STRATEGIES = {
  SINGLE: 'single',
  PURCHASE: 'purchase',
  QUICK_QUOTES_JLR: 'quickQuotesJLR',
  QUICK_QUOTES_JLR_CPO: 'quickQuotesJLR_CPO',
  STYLE_DLINV_CPL: 'STYLE_DLINV_CPL',
  STYLE_DLINV_ROI: 'STYLE_DLINV_ROI',
  TRIM_DLINV_DST: 'TRIM_DLINV_DST',
  TRIM_DLINV_ROI: 'TRIM_DLINV_ROI',
  TRIM_DLINV_ROI_ALL: 'TRIM_DLINV_ROI_ALL',
  TRIM_DLINV_TARGETED_ROI: 'TRIM_DLINV_TARGETED_ROI',
  MAKE_MODEL_TRIM_DLINV_DST: 'MAKE_MODEL_TRIM_DLINV_DST',
  MAKE_MODEL_INV_DST: 'MAKE_MODEL_INV_DST',
};

export const FORM_TYPES = {
  UNIFIED: 'unified',
  QUICK_QUOTES: 'quick_quotes',
  QUICK_QUOTES_CPL: 'quick_quotes_cpl',
  CONFIGURATOR: 'configurator',
  DIGITAL_RETAIL: 'digital_retail',
};

export const LEAD_FORM_CONFIGS = {
  UNIFIED: {
    strategy: STRATEGIES.SINGLE,
    leadType: LEAD_TYPES.BUY,
    submitLeadsType: '',
    formType: FORM_TYPES.UNIFIED,
    dataTrackingParent: {
      creativeId: 'unified',
    },
  },
  UNIFIED_PURCHASE: {
    strategy: STRATEGIES.PURCHASE,
    leadType: LEAD_TYPES.BUY,
    submitLeadsType: '',
    formType: FORM_TYPES.UNIFIED,
    dataTrackingParent: {
      creativeId: 'unified',
    },
  },
  QUICK_QUOTES_CPL: {
    strategy: STRATEGIES.STYLE_DLINV_CPL,
    leadType: LEAD_TYPES.BUY,
    submitLeadsType: '',
    formType: FORM_TYPES.QUICK_QUOTES_CPL,
    dataTrackingParent: {
      creativeId: 'quick-quotes-cpl',
    },
  },
  QUICK_QUOTES_ROI: {
    strategy: STRATEGIES.STYLE_DLINV_ROI,
    leadType: LEAD_TYPES.BUY,
    submitLeadsType: '',
    formType: FORM_TYPES.QUICK_QUOTES_CPL,
    dataTrackingParent: {
      creativeId: 'quick-quotes-cpl',
    },
  },
  QUICK_QUOTES_TRIM_DST: {
    strategy: STRATEGIES.TRIM_DLINV_DST,
    leadType: LEAD_TYPES.BUY,
    submitLeadsType: '',
    formType: FORM_TYPES.QUICK_QUOTES_CPL,
    dataTrackingParent: {
      creativeId: 'quick-quotes-cpl',
    },
  },
  QUICK_QUOTES_TRIM_ROI: {
    strategy: STRATEGIES.TRIM_DLINV_ROI,
    leadType: LEAD_TYPES.BUY,
    submitLeadsType: '',
    formType: FORM_TYPES.QUICK_QUOTES_CPL,
    dataTrackingParent: {
      creativeId: 'quick-quotes-cpl',
    },
  },
  QUICK_QUOTES_TRIM_ROI_ALL: {
    strategy: STRATEGIES.TRIM_DLINV_ROI_ALL,
    leadType: LEAD_TYPES.BUY,
    submitLeadsType: '',
    formType: FORM_TYPES.QUICK_QUOTES_CPL,
    dataTrackingParent: {
      creativeId: 'quick-quotes-cpl',
    },
  },
  QUICK_QUOTES_TRIM_TARGETED_ROI_ALL: {
    strategy: STRATEGIES.TRIM_DLINV_TARGETED_ROI,
    leadType: LEAD_TYPES.BUY,
    submitLeadsType: '',
    formType: FORM_TYPES.QUICK_QUOTES_CPL,
    dataTrackingParent: {
      creativeId: 'quick-quotes-cpl',
    },
  },
  QUICK_QUOTES_MAKE_MODEL_INV_DST: {
    strategy: STRATEGIES.MAKE_MODEL_INV_DST,
    leadType: LEAD_TYPES.BUY,
    submitLeadsType: '',
    formType: FORM_TYPES.QUICK_QUOTES_CPL,
    dataTrackingParent: {
      creativeId: 'quick-quotes-cpl',
    },
  },
  QUICK_QUOTES_JLR: {
    strategy: STRATEGIES.QUICK_QUOTES_JLR,
    leadType: LEAD_TYPES.BUY,
    submitLeadsType: '',
    formType: FORM_TYPES.QUICK_QUOTES,
    dataTrackingParent: {
      creativeId: 'dl_jlr_qq_base_lead_form',
    },
  },
  QUICK_QUOTES_JLR_CPO: {
    strategy: STRATEGIES.QUICK_QUOTES_JLR_CPO,
    leadType: LEAD_TYPES.BUY,
    submitLeadsType: '',
    formType: FORM_TYPES.QUICK_QUOTES,
    dataTrackingParent: {
      creativeId: 'dl_jlr_qq_base_lead_form',
    },
  },
  CONFIGURATOR: {
    strategy: STRATEGIES.STYLE_DLINV_CPL,
    leadType: LEAD_TYPES.BUY,
    submitLeadsType: '',
    formType: FORM_TYPES.CONFIGURATOR,
    dataTrackingParent: {
      creativeId: 'configurator',
    },
  },
  DIGITAL_RETAIL_OFFER_TYPE: {
    strategy: STRATEGIES.SINGLE,
    leadType: LEAD_TYPES.BUY,
    submitLeadsType: '',
    formType: FORM_TYPES.DIGITAL_RETAIL,
    dataTrackingParent: {
      creativeId: 'unified',
    },
  },
};
